/* SignIn page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    .field-container--grid .form-item {
      float: none;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
      clear: both;
    }
    .sign-in-container {
      .field-container-section {
        .field-container-div {
          max-width: 300px;
          .form-item {
            min-width: 300px;
          }
        }
      }
      width: 50%;
    }
    .social-login-section {
      margin-#{$ldirection}: 50px;
      margin-top: 140px;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 0;
      }
      .social-terms__container {
        .social-login {
          &__terms {
            padding-bottom: 32px;
          }
        }
      }
      .social-login {
        &__email-opt-in {
          .picker-label {
            text-align: $ldirection;
            max-width: 85%;
          }
        }
      }
    }
    .divider {
      .social-login__divider {
        &:before {
          #{$ldirection}: 109%;
          @include breakpoint($bp--largest-up) {
            #{$ldirection}: 107%;
          }
          @include breakpoint($bp--xxlarge-up) {
            #{$ldirection}: 106%;
          }
        }
        .inner {
          #{$ldirection}: 37vw;
          top: -96px;
          @include breakpoint($bp--largest-up) {
            #{$ldirection}: 38vw;
            top: -96px;
          }
          @include breakpoint($bp--xxlarge-up) {
            #{$ldirection}: 90ch;
            top: -106px;
          }
        }
      }
    }
  }
  .social-login-section {
    .social-login {
      &__email-opt-in {
        .picker {
          margin-top: 20px;
          text-align: $ldirection;
          display: flex;
        }
        @include breakpoint($bp--xxlarge-up) {
          margin-#{$ldirection}: 65px;
          margin-top: 20px;
          .picker {
            margin: 1em 0;
          }
        }
      }
      &__terms {
        text-align: $ldirection;
        padding: 0;
        @include breakpoint($bp--medium-up) {
          padding-#{$ldirection}: 14px;
        }
      }
    }
  }
}
/* Checkout page styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__divider {
          @include swap_direction(margin, 4px auto 12px);
          @include breakpoint($bp--medium-up) {
            @include swap_direction(margin, 25px auto 25px);
          }
          .inner {
            @include swap_direction(padding, 7px 8px);
            margin-bottom: 5px;
            @include breakpoint($bp--xxlarge-up) {
              @include swap_direction(padding, 7px);
            }
          }
        }
        &__container {
          padding-bottom: 4px;
          @include breakpoint($bp--medium-up) {
            padding: 0;
          }
        }
        &__email-opt-in {
          margin-top: 25px;
          .picker-checkbox {
            display: flex;
          }
          .social-login__opt-in-label.picker-label {
            text-align: #{$ldirection};
            @include breakpoint($bp--medium-up) {
              float: $ldirection;
            }
          }
        }
        &__terms {
          text-align: #{$ldirection};
          padding: 0;
          margin-top: 20px;
        }
      }
    }
  }
}

//social login gnav
.social-login.gnav {
  .picker-label {
    text-align: #{$ldirection};
    float: none;
    margin-#{$ldirection}: 39px;
  }
}

//Profile preference
section.profile-info {
  p.error-cpf {
    color: $color--chestnut--red;
  }
}
/* Write a Review */
#power_review_container {
  .social-login {
    .social-login__email-opt-in {
      .picker-checkbox {
        text-align: #{$ldirection};
        margin-top: 25px;
      }
    }
    .social-login__terms {
      text-align: #{$ldirection};
    }
  }
}
