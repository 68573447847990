.contact-us-page {
  &__title {
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    padding: 15px 0;
    letter-spacing: normal;
  }
  .contact-nav .details {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    p {
      margin: 0;
    }
    a {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      border: 0;
      font-size: 12px;
    }
  }
}
