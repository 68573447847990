// @file br_mobile.scss
// \brief Pull all parts of the BR-MOBILE theme together into one file

$mac-lover: true;
$loyalty_v2: true;
//loyalty font-families
$loyalty-tstar-font: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
$loyalty-ano-bold-font: 'ano-bold_regular', Helvetica, Arial, sans-serif;
// Sticky Checkout
$sticky_checkout: true;

@import 'overrides/overrides';

@import '../../mac_base/scss/mac-base';

// Live engage syltes
@import 'base/common';
@import 'base/cms';
@import 'sections/_gwp_mobile';

// SMACSS/sections/contactus
@import 'sections/mobile/contact-us/contact-us';

// SMACSS/sections/account
@import 'sections/mobile/account/account';

// SMACSS/sections/checkout
@import 'sections/mobile/checkout/checkout';

// Social Login
@import 'sections/_social_login';
@import 'sections/mobile/_power_review';

@import 'sections/loyalty/_loyalty_all';

// One Trust Cookie Styles.
@import '../../mac_base/scss/onetrust_cookie/cookie_settings_ot';

//Appointment Booking Styles
@import 'sections/mobile/appointment_booking';

// Cart Updates
@import 'sections/_enhanced_cart_page';
