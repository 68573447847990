$tier1color: #f7b7d3;
$tier2color: #ed2024;
$tier3color: #bfbfc3;
#site-header {
  &.site-header {
    .site-header__tools {
      .site-my-mac__contents {
        .site-my-mac__contents-footer__loyalty {
          .site-my-mac__contents-footer {
            &-title {
              width: 46%;
            }
            &-copy {
              width: 53%;
            }
          }
        }
      }
    }
  }
}

.site-container {
  .loyalty {
    &__content-bottom {
      .macselect-benefits {
        &__container {
          @include swap_direction(padding, 0 0 0 20px);
          background: $color--white;
          width: 100%;
          .macselect-benefits__section {
            &-fields {
              width: 50%;
              .macselect-benefits__section {
                &-title {
                  height: 175px;
                }
                &-copy {
                  ul {
                    li.offer {
                      height: 70px;
                      line-height: 1;
                      padding: 30px 0 10px;
                      &:nth-child(2) {
                        padding-top: 25px;
                      }
                      .special-offer {
                        font-family: $loyalty-tstar-font;
                        font-size: 10px;
                        letter-spacing: 1px;
                      }
                    }
                  }
                }
              }
            }
            &-levels {
              width: 50%;
              &__header {
                height: 175px;
              }
              &__header-title {
                font-family: $loyalty-ano-bold-font;
                font-size: 18px;
                @include breakpoint($bp--large-up) {
                  font-size: 30px;
                }
              }
              .macselect-benefits__section {
                &-level2,
                &-level3,
                &-level4 {
                  width: 33.33%;
                  .macselect-benefits__section {
                    &-copy {
                      li {
                        height: 70px;
                        padding-top: 20px;
                      }
                    }
                  }
                }
              }
              &__header-level {
                font-size: 14px;
              }
              &__header-status {
                font-size: 12px;
              }
            }
          }
          &__content {
            font-family: $ano-bold_regular-font;
            font-size: 12px;
            &__right,
            &__left {
              .macselect-benefits__container__content {
                &__level2 {
                  height: 180px;
                  li {
                    height: 65px;
                  }
                  li:nth-child(1) {
                    padding-top: 28px;
                  }
                  li:nth-child(3) {
                    padding-top: 20px;
                  }
                }
                &__level3 {
                  height: 180px;
                  li {
                    height: 64px;
                  }
                  li:nth-child(1),
                  li:nth-child(2) {
                    padding-top: 20px;
                  }
                }
                &__level4 {
                  height: 156px;
                  li {
                    height: 156px;
                  }
                  li:nth-child(1) {
                    padding-top: 78px;
                  }
                }
              }
            }
            &-status {
              margin-#{$ldirection}: -20px;
              width: 120px;
            }
            &__left {
              width: 74px;
              border-#{$rdirection}: 1px solid $color--white;
              .macselect-benefits {
                &__container {
                  &__content-title {
                    font-size: 14px;
                    white-space: nowrap;
                    transform: rotate(-90deg) translate(-89px, -20px);
                  }
                  &__content-level {
                    transform: rotate(-90deg) translate(-60px, -6px);
                  }
                  &__content-status {
                    transform: rotate(-90deg) translate(-30px, 12px);
                  }
                  &__content {
                    &__level2,
                    &__level3,
                    &__level4 {
                      text-align: center;
                    }
                  }
                }
              }
            }
            &__right {
              border-#{$rdirection}: 1px solid $color--white;
              ul {
                li {
                  line-height: 1;
                  border-#{$rdirection}: 0;
                  border-#{$ldirection}: 0;
                }
              }
            }
          }
        }
        .macselect-benefits {
          &__section-terms-container {
            padding: 10px 0 5px;
            p {
              font-family: $loyalty-tstar-font;
            }
          }
          &__section-button-container {
            .button {
              @include breakpoint($bp--medium-up) {
                padding: 0 45px;
              }
            }
          }
        }
      }
      h3 {
        @include breakpoint($bp--medium-up) {
          font-size: 30px;
        }
      }
      h2 {
        font-size: 36px;
        margin: 10px 0;
        line-height: 1;
      }
      .mac-select {
        &__marketing-page {
          &__title,
          &__romance-header {
            width: 100%;
            font-family: $loyalty-ano-bold-font;
            @include breakpoint($bp--large-up) {
              width: 55%;
            }
          }
          &__romance-header,
          &__romance-text {
            padding: 0 7%;
            @include breakpoint($bp--medium-up) {
              padding: 0;
            }
          }
          &__intro,
          &__romance-text {
            font-family: $loyalty-tstar-font;
          }
          &__lover {
            @include breakpoint($bp--medium-up) {
              text-align: #{$ldirection};
              margin: 7% 2% 0;
            }
            @include breakpoint($bp--xlarge-up) {
              margin: 0 15%;
              text-align: #{$ldirection};
            }
          }
          &__romance {
            margin: 0;
            @include breakpoint($bp--medium-up) {
              text-align: #{$ldirection};
              margin: 7% 3% 4%;
            }
            @include breakpoint($bp--xlarge-up) {
              margin: 0 15% 5%;
              text-align: #{$ldirection};
            }
          }
          &__header-col1 {
            @include breakpoint($width-large - 1) {
              width: 60%;
            }
          }
          .loyalty {
            &__content-bottom {
              &__section-container-shade {
                @include breakpoint($bp--medium-up) {
                  #{$ldirection}: -38%;
                }
                @include breakpoint($bp--xxlarge-up) {
                  #{$ldirection}: -20%;
                }
                @include breakpoint($bp--medium-landscape) {
                  #{$ldirection}: -29%;
                }
              }
              &__section-container-header {
                @include breakpoint($bp--medium-up) {
                  width: 45%;
                }
                @include breakpoint($bp--xlarge-up) {
                  width: 30%;
                }
              }
              &__section-container-content {
                z-index: 2;
              }
            }
          }
          .mac-select {
            &__marketing-page {
              &__header-col2 {
                padding: 0;
                .mac-select {
                  &__marketing-page {
                    &__signin,
                    &__join,
                    &__join__hyperlink {
                      font-size: 17px;
                      font-family: $ano-bold_regular-font;
                      width: 250px;
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .progress_bar_left {
      .mac_lover_logo {
        @include breakpoint($bp--medium-up) {
          width: 300px;
        }
      }
      .progress_bar_left {
        &--header {
          font-size: 26px;
          @include breakpoint($bp--medium-up) {
            font-size: 36px;
          }
        }
        &--info {
          font-size: 14px;
          font-family: $roboto-mono_regular-font;
        }
      }
      &--links {
        float: #{$ldirection};
        width: 100%;
        margin-top: 22%;
        @include breakpoint($bp--large-up) {
          margin-top: 2%;
        }
        &-about,
        &-faq {
          font-size: 20px;
          border-bottom: 2px solid $color--black;
        }
      }
    }
    .progress_bar_text {
      top: 27%;
      #{$ldirection}: 0;
      width: 100%;
      .points_current {
        font-family: $ano-bold_regular-font;
        line-height: 0.8;
      }
      .points_header {
        font-family: $ano-bold_regular-font;
        font-size: 20px;
      }
      .reward_status {
        font-family: $ano-bold_regular-font;
        word-spacing: normal;
        font-size: 10px;
      }
      .current_tier {
        font-family: $ano-bold_regular-font;
        word-spacing: normal;
      }
    }
    &__panel {
      &__offers {
        &__list-container {
          position: relative;
          bottom: 75px;
          @include breakpoint($bp--medium-up) {
            bottom: 85px;
          }
        }
        &__offer-wrapper {
          text-align: #{$ldirection};
        }
        &__offer-name {
          margin: 15px 0;
        }
        &__title {
          margin-bottom: 15px;
          display: inline-block;
          font-family: $loyalty-ano-bold-font;
        }
        &__offer-image-container {
          border: none;
        }
        &__offer-header {
          font-family: $ano-bold_regular-font;
          margin-bottom: 5px;
          @include breakpoint($bp--medium-up) {
            margin-#{$ldirection}: 20px;
            min-height: auto;
          }
        }
        &__offer-description {
          font-size: 13px;
          margin-bottom: 10px;
          @include breakpoint($bp--medium-up) {
            margin-#{$ldirection}: 20px;
            min-height: auto;
          }
        }
        &__messages {
          margin-bottom: 24px;
        }
        &__cta {
          @include breakpoint($bp--medium-up) {
            margin-#{$ldirection}: 16px;
          }
        }
        &__offer-valid {
          margin-bottom: 25px;
          font-family: $ano-bold_regular-font;
          font-size: 12px;
          @include breakpoint($bp--medium-up) {
            @include swap_direction(margin, 0 0 15px 20px);
          }
        }
        &--content {
          .loyalty-panel-birthday-text {
            font-family: $ano-bold_regular-font;
            width: 100%;
            @include breakpoint($bp--medium-up) {
              font-size: 42px;
            }
          }
          &-btn {
            padding: 5px 35px;
            @include breakpoint($bp--medium-up) {
              padding: 0 42px;
            }
          }
        }
        &__button,
        &__remove,
        &__redeemed,
        &__selected {
          display: inline-block;
          padding: 5px 35px;
          font-family: $ano-bold_regular-font;
          @include breakpoint($bp--medium-up) {
            padding: 0 42px;
          }
        }
      }
    }
  }
  .progress_bar {
    @include breakpoint($bp--large-up) {
      @include swap_direction(padding, 2% 21% 3% 17%);
    }
    .total_progress {
      font-family: $ano-bold_regular-font;
      #{$ldirection}: 0;
      width: 100%;
    }
    .points {
      &__expiration {
        font-family: $roboto-mono_regular-font;
        #{$ldirection}: 0;
        width: 100%;
      }
    }
    .progress-bar-container {
      width: 230px;
      height: 230px;
      #myCanvas {
        width: 100%;
        height: 100%;
      }
    }
  }
  .account-loyalty {
    .mac-select {
      &__color-tier1 {
        color: $color--pink-tier1;
      }
      &__color-tier2 {
        color: $color--red-tier2;
      }
      &__color-tier3 {
        color: $color--gray-tier3;
      }
      &__account-panel {
        &__header-left {
          width: 60%;
          h4 {
            font-size: 26px;
          }
        }
        &__header-right {
          width: 40%;
          h4 {
            font-size: 46px;
            margin-bottom: 0;
            @include breakpoint($bp--medium-up) {
              font-size: 30px;
            }
          }
          h6 {
            font-size: 20px;
          }
        }
        &__content-title {
          span {
            font-size: 32px;
            font-family: $ano-bold_regular-font;
          }
        }
        &__content-spend {
          font-family: $ano-bold_regular-font;
          font-size: 16px;
        }
        &__content-details {
          display: inline-block;
        }
      }
    }
  }
  #loyalty {
    &__panel {
      &__offers {
        border-bottom: 1px solid $color--gray--lighter;
        padding: 0;
      }
    }
  }
  .loyalty__panel {
    &.is-user {
      padding: 7% 15% 8% 15%;
      @include breakpoint($bp--large-up) {
        padding: 2% 21% 3% 17%;
      }
      .loyalty__panel__offers {
        &__title,
        &__offer {
          text-align: $ldirection;
          padding: 0;
        }
        &__list-container {
          bottom: 0;
        }
        &__offer-description {
          @include breakpoint($bp--large-up) {
            margin: 0;
          }
        }
        &__offer-makeup_session {
          margin-bottom: 0;
          line-height: 2;
        }
        &__offer-code-wrap {
          margin-bottom: 30px;
        }
        &__offer-terms {
          display: none;
        }
      }
    }
  }
  .account-loyalty,
  .loyalty__panel,
  .site-my-mac__contents {
    .mac-select__color {
      &-tier1 {
        color: $tier1color;
      }
      &-tier3 {
        color: $tier3color;
      }
    }
  }
}
body {
  &.toolbar-drawer {
    padding-top: 0 !important;
  }
}
.loyalty-select {
  .form-item--cpf {
    margin-#{$ldirection}: 5%;
    @include breakpoint($bp--medium-up) {
      margin-#{$ldirection}: 28%;
    }
  }
  .form-item_birthday {
    .form-item_month_year {
      .form-item {
        padding-#{$rdirection}: 10px;
        @include breakpoint($bp--medium-up) {
          padding-#{$rdirection}: 15px;
        }
        &--day {
          width: 26%;
        }
        &--month,
        &--year {
          width: 37%;
          clear: none;
        }
      }
    }
  }
}
.loyalty-signin {
  max-width: 660px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .form-item {
    .field {
      margin-bottom: 15px;
    }
  }
}
.loyalty__content-bottom {
  .macselect-benefits__container {
    .macselect-benefits__section-levels {
      .macselect-benefits {
        &__section-level2 {
          .macselect-benefits__section {
            &-levels__header {
              background-color: $tier1color;
            }
            &-copy {
              li {
                span {
                  background-color: $tier1color;
                }
              }
            }
          }
        }
        &__section-level4 {
          .macselect-benefits__section {
            &-levels__header {
              background-color: $tier3color;
            }
            &-copy {
              li {
                span {
                  background-color: $tier3color;
                }
              }
            }
          }
        }
      }
    }
  }
}
.loyalty__panel__offers,
.loyalty__panel__offers__sku {
  .slick-list {
    .slick-slide {
      min-height: 550px;
    }
    .shade-selector {
      &__header {
        h6 {
          font-size: 18px;
          margin: 10px 0;
        }
      }
      &.accordionPanel {
        background: none;
      }
    }
  }
  &__button-container {
    position: absolute;
    bottom: 0;
  }
  &__header {
    &-container {
      @include breakpoint($width-large - 1) {
        display: none;
      }
      @include breakpoint($bp--large-up) {
        display: block;
      }
    }
  }
}
#loyalty__content {
  .loyalty__panel__history__table {
    .loyalty__panel__transactions {
      &__level__description {
        padding: 0 17%;
      }
      &__list__column-points,
      &__list__column-spent {
        text-align: #{$rdirection};
      }
      &__paginate {
        text-align: center;
        margin-top: 20px;
        .prev_disabled,
        .next_disabled {
          color: $color--gray--lightest;
          pointer-events: none;
        }
      }
    }
  }
}

h3 {
  &.at-h5__typography {
    font-family: $loyalty-ano-bold-font;
    font-size: 2rem;
    line-height: 1.25;
    margin-top: 5%;
  }
}

.checkout__content {
  .loyalty-offer-code-panel {
    .offer-code-content__wallet {
      .slick-track {
        display: flex;
      }
      .slick-prev,
      .slick-next {
        top: 35%;
        @include breakpoint($width-large - 1) {
          top: 32%;
        }
      }
      .slick-slide {
        padding-bottom: 20px;
        .offer-code-wallet {
          &__button {
            width: 100%;
            @include breakpoint($bp--large-up) {
              min-width: auto;
              height: auto;
              padding: 12px 25px;
              line-height: inherit;
              position: absolute;
              bottom: 0;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .disabled {
              background: $color--gray--lightest;
            }
          }
          &__code {
            &__image-container {
              min-height: 195px;
              border: none;
            }
            &__description,
            &__description-title,
            &__description-points,
            &__description-expiration {
              font-weight: normal;
              line-height: 1.2;
            }
            &__description {
              text-align: center;
              @include breakpoint($bp--large-up) {
                text-align: $ldirection;
                min-height: 280px;
                position: relative;
              }
            }
            &__description-title {
              line-height: 1.13;
              font-family: $ano-bold_regular-font;
              letter-spacing: 0.5px;
              overflow: hidden;
              @include breakpoint($bp--large-up) {
                font-size: 18px;
              }
            }
            &__description-points {
              @include breakpoint($bp--large-up) {
                width: 100%;
                position: absolute;
                bottom: 50px;
              }
            }
          }
        }
      }
    }
  }
}
.account-order-history {
  .shipments-list {
    .product {
      .product {
        &__image--default {
          @include breakpoint($bp--large-up) {
            width: 140px;
            height: 160px;
          }
        }
      }
    }
  }
}
.order-details-page {
  .cart-item {
    &__thumb-image {
      width: 140px;
      height: 160px;
    }
  }
}
.checkout__index-content {
  .rewards-panel {
    &.accordionPanel {
      background: $color--white;
    }
  }
  .loyalty-offer-code-panel {
    .offer-code-content {
      &__wallet {
        .slick-slide {
          .offer-code-wallet {
            &__button {
              width: 90%;
              &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }
          .offer_image {
            margin: 0 auto;
            @include breakpoint($bp--medium-up) {
              height: 190px;
            }
          }
        }
      }
    }
  }
  .recommended-products-panel {
    clear: both;
  }
}
#loyalty__panel__offers {
  .loyalty__panel__offers__offer {
    padding: 40px;
  }
}
.product-brief-carousel {
  &.grid--mpp__carousel {
    .slick-next {
      @include swap_direction(padding, 0 10px 0 0);
      #{$rdirection}: 8px;
    }
  }
}
.responsive-container {
  position: relative;
}
#loyalty__panel__transactions {
  .loyalty__panel__transactions {
    &__list__column-spent {
      @include breakpoint($width-large - 1) {
        display: table-cell;
      }
    }
  }
}
.loyalty-points-content {
  &__summary {
    @include breakpoint($bp--medium-up) {
      float: #{$rdirection};
      padding-top: 20px;
    }
  }
}
.viewcart {
  .checkout__content {
    margin-bottom: 70px;
    @include breakpoint($bp--large-up) {
      margin-bottom: 0;
    }
  }
}
.mac-early-access-popup-colorbox {
  .mac-early-access-popup {
    &__earn-more-message {
      width: auto;
      padding-top: 11px;
      padding-#{$ldirection}: 4px;
      font-size: 14px;
      .button {
        padding: 0 35px;
        margin: 20px 0;
        width: 100%;
      }
    }
  }
}
.loyalty-disabled {
  pointer-events: none;
  background: $color--gray--lightest;
}
