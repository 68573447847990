.enhanced-cart-page {
  .checkout.viewcart {
    .checkout-header__title,
    .favorites-panel__header--title,
    .past-purchases-panel__title {
      width: 100%;
    }
    .shipping-title {
      margin: 10px;
    }
    .checkout-header {
      .is-mobile {
        font-size: 23px !important;
      }
      .checkout-header__subhead {
        border: 0;
      }
    }
    .need-help-panel {
      #checkout_chat {
        a,
        a:visited,
        a:hover {
          color: $color--white;
          border-bottom-color: $color--white;
        }
      }
    }
    .links-panel {
      background: $black !important;
      padding-bottom: 60px;
      margin-bottom: 32px;
      @include breakpoint($bp--medium-up) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      a,
      a:visited {
        color: $color--white !important;
      }
      a:hover {
        border-bottom-color: $color--white;
      }
      .links-panel__title {
        border: 0;
      }
    }
    .checkout__sidebar {
      .container.order-summary__shipping {
        display: flex;
        width: 100%;
        .order-summary__shipping-method {
          width: 50%;
          display: block;
          margin: 8px 0 20px;
          @include breakpoint($bp--medium-up) {
            width: 58%;
          }
        }
        .cpf-field {
          width: 50%;
          @include breakpoint($bp--medium-up) {
            width: 40%;
          }
        }
      }
      .viewcart-cpf-field.panel {
        margin: 0;
        padding-#{$rdirection}: 0;
        .postcode-zipfields {
          input {
            padding: 8px;
            background-color: $color--gray--white;
            @include breakpoint($bp--medium-up) {
              padding: 5px;
              width: 100%;
            }
            &:focus {
              border-color: $color--gray--lighter;
            }
          }
        }
      }
      .discount.value {
        margin-bottom: 15px;
      }
      .shipping-label-data {
        width: 100%;
      }
      .offer-code-panel {
        &__content {
          .offer-code__form {
            #offer_code {
              .offer-code__input {
                .field {
                  height: 48px;
                  background-color: $color--white;
                }
              }
            }
          }
        }
        .offer-code__byline {
          padding: 0;
          margin: 0 0 15px;
          color: $color--green--medium;
          @include breakpoint($bp--medium-up) {
            margin: 0 15px 15px;
          }
        }
      }
    }
    .cart-item__price-content {
      position: relative;
      .cart-item__price {
        @include breakpoint($bp--medium-up) {
          flex-direction: column !important;
        }
        .cart-item__product-price--non-sale,
        .cart-item__product-price--sale {
          padding-bottom: 5px;
        }
      }
      .discount,
      .price2 {
        top: 0;
        position: absolute !important;
        right: 0;
      }
      .cart-item__total {
        top: 0;
        @include breakpoint($bp--medium-up) {
          top: 2px;
        }
      }
      .cart-item__product-price {
        flex-direction: column !important;
        display: flex;
      }
    }
    #samples-panel {
      .product__select.sample-select-container {
        .sample-select-button.button {
          width: 100% !important;
        }
      }
    }
    .past-purchase-section {
      @include breakpoint($bp--medium-up) {
        margin-bottom: 20px;
      }
    }
    .rewards-panel {
      border: 0 !important;
      margin: 0;
    }
    .cart-item__add-to-favorites {
      width: auto;
      @include breakpoint($bp--medium-up) {
        width: unset;
      }
    }
    #viewcart-buttons-panel {
      .continue-checkout__lock--icon {
        display: none;
      }
      .payments-icon {
        .master-card {
          background: url('/media/images/checkout/card_mastercard.png') no-repeat;
        }
        .visa-card {
          background: url('/media/images/checkout/card_visa.png') no-repeat;
        }
        .amex-card {
          background: url('/media/images/checkout/card_amex.png') no-repeat;
        }
        .diners-card {
          background: url('/media/images/checkout/card_diners.png') no-repeat;
        }
        .elo-card {
          background: url('/media/images/checkout/elo.gif') no-repeat;
        }
        .hiper-card {
          background: url('/media/images/checkout/hipercard.gif') no-repeat;
        }
        .boleto {
          background: url('/media/images/checkout/boleto.png') no-repeat;
        }
        .paypal {
          display: none;
        }
        .master-card,
        .visa-card,
        .amex-card,
        .diners-card,
        .elo-card,
        .hiper-card,
        .boleto {
          @include swap_direction(margin, 5px 16px 0 0);
          background-position: center;
          background-size: 100%;
          height: 16px;
          width: 23px;
        }
      }
    }
    #promo-panel {
      display: none;
    }
    .checkout__content {
      margin-bottom: 40px;
      @include breakpoint($bp--medium-up) {
        margin-bottom: 0;
      }
      .loyalty-offer-code-panel {
        padding: 10px 27px 7px;
        &__select {
          margin: 10px 0 20px;
        }
        .offer-code-content__wallet {
          margin: auto;
          .slick-next {
            #{$rdirection}: 30px;
          }
          .slick-prev {
            #{$ldirection}: 30px;
          }
          .slick-slide {
            .offer-code-wallet__code__image-container {
              min-height: auto;
              height: auto;
            }
          }
        }
        .offer-code-wallet__button--remove {
          padding: 10px;
        }
      }
      .checkout-header__signin {
        width: 100%;
        margin: 23px 0 0;
      }
      .accordionPanel.js-loyalty-section {
        padding: 0;
        border: 0;
      }
      .viewcart-panel__content {
        .past-purchases-panel {
          .checkout__panel-content {
            .past-purchases-panel__content {
              .product__desc {
                .shades-list,
                .product_name {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    #error_offer_criteria_not_met_funcionarios {
      display: none;
    }
    .btn-mini {
      font-size: 14px;
    }
  }
  .viewcart-samples-panel#samples-panel-content {
    @include breakpoint($bp--medium-up) {
      margin-top: 23px;
    }
    .viewcart-samples__message {
      text-align: start;
    }
    .viewcart-samples__title.checkout-header__title {
      width: 0;
      margin: 0;
    }
    .viewcart-samples {
      &__success-title,
      &__title {
        width: 100%;
      }
    }
  }
}

.product-brief-v2,
.product-full-v1 {
  .product-price--sale {
    color: $color--red;
  }
}
