$border-color: #bbbbbb;

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $border-color;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
  }
  .address_lookup_wrapper li {
    padding-bottom: 1em;
  }
}

.site-container {
  .grid--mpp {
    .product--teaser {
      .product__footer {
        .product__add-to-bag {
          display: none;
        }
        .product__inventory-status {
          .js-inv-status-3.coming-soon {
            padding: 0px;
            line-height: 14px;
            height: auto;
          }
          .js-inv-status-2,
          .js-inv-status-3 {
            float: none;
            width: 100%;
            a.notify_me {
              @include body-text--small-bold;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50px;
              height: 45px;
              text-align: center;
              width: 100%;
              clear: both;
              position: relative;
            }
            .temp-outof-stock_text,
            .coming-soon_text {
              position: static;
              margin-top: 20px;
              text-align: center;
              display: none;
            }
          }
        }
      }
      &.product--single-not-orderable {
        &.prod_inv_status-7 {
          .fade-non-shoppable-product {
            opacity: 1;
          }
          .product__footer {
            .product__link-to-spp {
              display: block;
            }
          }
        }
      }
      &.product--shaded {
        &.prod_inv_status-7 {
          .product__footer {
            .product__inventory-status {
              .sold-out {
                display: none;
              }
            }
          }
        }
        &.all-shaded-sold-out {
          .product__footer {
            .product__link-to-spp {
              display: none;
            }
            .product__inventory-status {
              .sold-out {
                display: block;
              }
            }
          }
        }
      }
    }
    .grid--mpp__item {
      .prod_inv_status-3 {
        &.product--single-not-orderable {
          .product__inventory-status {
            .coming-soon {
              font-size: 1.5rem;
              text-align: center;
              height: auto;
              line-height: 64px;
              display: block;
              width: 100%;
              clear: both;
              position: relative;
            }
          }
        }
      }
    }
    .prod_inv_status-3 {
      .product__footer {
        .product__inv-status-item.coming-soon {
          @include swap_direction(padding, 6px 0);
        }
      }
    }
    .favorites-board__item {
      .product__footer {
        a.notify_me {
          @include swap_direction(margin, 0);
          padding: 20px 0;
          width: 100%;
          position: static;
        }
        .product-item__out-of-stock {
          text-align: right;
        }
        .product-item__comingsoon {
          text-align: right;
        }
        .product-item__sold-out {
          text-align: right;
        }
      }
    }
    .prod_inv_status-2,
    .prod_inv_status-3,
    .prod_inv_status-7 {
      .product__footer {
        .product__link-to-spp {
          display: none;
        }
        .product__link-to-spp_single_shade {
          display: none;
        }
      }
    }
  }
  .page--spp__product {
    .product__footer {
      a.notify_me {
        padding: 0px 20px;
        width: 100%;
        position: static;
        font-size: 1.5em;
        height: 60px;
        line-height: 64px;
        margin: 0;
      }
    }
    .product-full {
      &__details-container {
        .product-full__price-details {
          margin-bottom: 20px;
        }
        .product-full__installment_price {
          height: auto;
          margin-top: 0;
        }
      }
      &__offer {
        margin-top: 10px;
      }
      &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
  .page--spp__product {
    .product__footer {
      .product__price_ppu {
        @include swap_direction(padding, 0 15px);
      }
    }
  }
  .layout--artistry-artist {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          a.notify_me {
            font-size: 1.5rem;
            text-align: center;
            height: 60px;
            line-height: 64px;
            display: block;
            width: 100%;
            clear: both;
            position: relative;
            margin: 0;
            @include swap_direction(padding, 0 15px);
          }
          li.coming-soon,
          li.temp-out-of-stock {
            width: 100%;
            .coming-soon_text,
            .temp-outof-stock_text {
              position: static;
              margin-top: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .search-filter__container {
    .search-dimension__header.results-header {
      margin: 0 0 0.25em;
      border: medium none;
    }
  }
  .artistry-video-player-wrapper {
    .product__footer {
      .product__price_ppu {
        display: none;
      }
    }
  }
  .cart-dialog.cart-popup {
    overflow: hidden;
    height: auto;
    .continue-button {
      width: 135px;
      #continue_chat {
        height: auto;
        line-height: normal;
        font-size: 1em;
        padding: 0;
      }
    }
    .end-chat {
      width: 100px;
      input#end_chat {
        height: auto;
        line-height: normal;
        font-size: 1em;
        padding: 0;
        background-color: black !important;
        border: none !important;
        color: white !important;
      }
    }
  }
  .page--spp__product {
    .product__rating {
      .product__rating--no_rating {
        display: block;
        text-align: right;
      }
    }
  }
  .artistry-video-player-wrapper {
    .artistry-video-player__products {
      @include swap_direction(padding, 0 15px);
      .prod_inv_status-3 {
        .product__footer {
          .coming-soon_text {
            text-align: center;
            top: 12px;
            position: relative;
          }
        }
      }
      .product {
        &__footer {
          width: 100%;
          padding-#{$ldirection}: 0;
          #{$rdirection}: 0;
          a.notify_me {
            height: 60px;
            line-height: 64px;
            font-size: 1.5em;
          }
          .temp-out-of-stock,
          .coming-soon {
            a.notify_me {
              @include swap_direction(padding, 0 20px);
              @include swap_direction(margin, 0);
              width: 100%;
              position: static;
            }
            .temp-out-of-stock__text {
              #{$rdirection}: 51%;
              #{$ldirection}: 2%;
              text-align: center;
              top: -10%;
            }
          }
        }
        &__price {
          float: $rdirection;
          @include swap_direction(margin, 25px 70px 0);
        }
        &__price-installment {
          float: $rdirection;
        }
        &--teaser {
          .product__detail {
            .product__footer {
              .product__add-to-faves {
                @include swap_direction(margin, 30px 0px);
              }
            }
          }
        }
        &__add-to-bag {
          height: 60px;
          line-height: 64px;
        }
      }
      .product__price {
        margin: 25px 0;
        float: left;
        padding-left: 50%;
      }
      .product__price-installment {
        float: left;
        padding: 0 0 2% 50%;
      }
      .product--sku-product.prod_inv_status-2 {
        .product__footer {
          .product__inventory-status {
            margin-bottom: 0px;
            .temp-out-of-stock div {
              position: static;
              top: 0px;
              padding-top: 10px;
            }
          }
        }
      }
      .product--teaser {
        &.product--teaser--tiny {
          &.prod_inv_status-1,
          &.prod_inv_status-2 {
            min-height: 320px;
          }
        }
      }
    }
  }
  .content {
    .mpp-responsive-container {
      .product-brief__inventory-status {
        .product-inventory-status__temp-oos-text {
          .notify-status {
            max-width: 190px;
            text-align: center;
          }
        }
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 275px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
  .field {
    .email_input {
      float: left;
      @if $fonts_update == true {
        width: 60%;
      } @else {
        width: 65%;
      }
    }
    .align-r {
      float: left;
      margin-left: 10px;
      .input-btn {
        height: 58px;
      }
    }
    .terms_condition_cont {
      float: right;
      margin: 10px 5px 0 0;
    }
  }
}

.artistry-video-player__products {
  .temp-outof-stock_text {
    display: none;
  }
}

.artistry--artist {
  .product__footer {
    .product__inventory-status {
      .temp-outof-stock_text {
        display: none;
      }
    }
  }
}

.site-header__tools {
  .block-template-site-email-signup-ca-v1 {
    display: none;
  }
}

.viewcart {
  .viewcart-buttons-panel {
    a.continue-checkout {
      margin-top: 7px;
    }
  }
  .bottom-viewcart-buttons {
    .viewcart-buttons-panel {
      background: transparent;
    }
  }
  .checkout__content {
    .cart-items {
      .qty {
        .selectbox {
          border-color: transparent;
        }
      }
      &__total {
        margin-top: 1.5em;
      }
    }
  }
}

.page-products {
  .grid--mpp {
    margin-top: 60px;
    .product__description-short {
      margin: 14px 0 15px 0;
    }
  }
  .sec-nav {
    &__sections {
      .sec-nav__link {
        display: none;
      }
    }
  }
}

.section-artistry-mac-artist-gregory-arlt {
  .carousel {
    .media-block__cta {
      position: absolute;
    }
  }
}

.artistry--artist {
  .carousel {
    .media-block__cta {
      position: absolute;
    }
  }
  .media-block--video {
    .play {
      bottom: 50%;
    }
  }
}

.grid--mpp {
  .product__detail {
    .product__price_ppu {
      display: none;
    }
    .product__price-installment {
      clear: both;
    }
  }
}

.grid--mpp,
.mobile-collection-products-carousel,
.collection-detail-formatter {
  .product--teaser {
    .product__link-to-spp_single_shade {
      @include body-text--small-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      height: 45px;
      color: white;
      text-align: center;
      @include swap_direction(padding, 0 20px);
      background-color: black;
      text-transform: uppercase;
      cursor: pointer;
      width: 100%;
      clear: both;
      float: $ldirection;
    }
    .product__footer {
      .product__add-to-bag {
        display: none !important;
      }
    }
  }
  .product--teaser.product--shaded {
    .product__link-to-spp_single_shade {
      display: none;
    }
  }
  .prod_inv_status-2,
  .prod_inv_status-3,
  .prod_inv_status-7 {
    .product__footer {
      .product__link-to-spp_single_shade,
      .product__link-to-spp {
        display: none !important;
      }
    }
  }
}

.product__link-to-spp_single_shade {
  .collection-quickshop & {
    display: none;
  }
  .product--full & {
    display: none;
  }
}

.artist--products__carousel {
  .product--teaser {
    .product__footer {
      .product__link-to-spp_single_shade {
        display: none;
      }
    }
    &.prod_inv_status-3 {
      &.product--single-not-orderable {
        .product__inventory-status {
          .coming-soon {
            display: block;
          }
        }
      }
    }
  }
}

.checkout {
  .panel.need-help-panel {
    background: #f0f0f0;
    color: black;
  }
}

.checkout {
  .panel.need-help-panel {
    a {
      color: black;
      &:visited {
        color: black;
      }
    }
  }
}

.checkout {
  .panel.links-panel {
    background: #f0f0f0;
    color: black;
  }
  .panel.need-help-panel {
    background: #f0f0f0;
    color: black;
  }
}

.checkout {
  .panel.links-panel {
    a {
      color: black;
      &:visited {
        color: black;
      }
    }
  }
  .checkout-header {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .payment-panel {
    .payment_form {
      .submit {
        display: none;
      }
    }
  }
}

.device-mobile {
  .custom-grid {
    overflow-x: inherit;
  }
}

.site-container {
  overflow-x: inherit;
  padding-top: 50px;
  @include breakpoint($bp--xxsmall-up) {
    padding-top: 0;
  }
}

.mac-select {
  &__marketing-page {
    .mac-select__marketing-page {
      &__benefits__section {
        &-title {
          height: 115px;
        }
        &-all {
          ul {
            li {
              &.offer {
                width: 235px;
              }
            }
          }
        }
        li {
          .benefits {
            width: 60%;
          }
          .special-offer {
            width: 75%;
            padding: 0 10px;
          }
          &.marketing-offer {
            height: 150px;
          }
        }
      }
      &__message {
        font-size: 18px;
      }
      &__title {
        font-size: 28px;
      }
    }
  }
}
.select-marketing-page,
.loyalty,
.loyalty__content-bottom {
  h2,
  h3,
  h4,
  h6,
  .btn,
  .btn--full {
    font-family: $ano-bold_regular-font;
  }
  h2 {
    font-size: 25px;
  }
  h3,
  h4 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
}
.loyalty__content-bottom {
  &__section-title {
    line-height: 1.3;
  }
}
#loyalty__panel__points {
  .mac-select {
    &__account-panel {
      .mac-select__account-panel {
        &__content-spend {
          white-space: normal;
        }
      }
    }
  }
}

.product-foundation-vto__overlay-button {
  background-position: 20px;
}

.site-header__wrapper {
  .header-search__typeahead-wrapper {
    .search-suggestions-title {
      top: 22px;
    }
  }
}
