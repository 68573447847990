.site-container {
  .responsive-container {
    .picker-checkbox {
      .picker-label {
        max-width: 85%;
      }
    }
  }
  .account-nav {
    .responsive-container {
      .account-utilities {
        clear: both;
      }
    }
  }
  .account-page {
    .orders-list__table {
      th {
        padding-right: 5px;
        @if $fonts_update == true {
          font-size: 0.7rem;
        } @else {
          font-size: 1.2rem;
        }
      }
      td {
        font-size: 11px;
        padding: 1em 1%;
      }
    }
  }
  .profile-info__fieldset {
    .picker-radio {
      float: left;
      width: auto;
    }
    .gender_col,
    .sms_col,
    .birthday_col {
      label {
        display: block;
      }
    }
    .gender_col {
      margin-top: 15px;
    }
  }
  .contact-us-page {
    .user-data,
    #email_an_artist {
      width: 100% !important;
      select {
        margin-bottom: 23px;
      }
    }
    .customer-service-title {
      width: auto;
      float: none;
      margin-bottom: 20px;
    }
    .customer-service-description {
      width: auto;
      float: none;
    }
    input[type='text'],
    input[type='email'] {
      width: 100% !important;
      margin: 0 0 23px;
    }
  }
  .email-signup-new-user {
    margin: 0 auto !important;
    #email_new_user_signup {
      .picker-checkbox {
        .picker-handle {
          margin: 0 12px 0 2px;
        }
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .product-header {
          display: flex;
          flex-direction: row;
          h6 {
            font-size: 9px;
          }
          .rating,
          .product {
            margin: 0;
            width: 24%;
            text-align: $ldirection;
          }
          .quantity {
            margin: 0 0 0 8px;
            width: 20%;
            text-align: center;
          }
          .price {
            margin: 0 63px 0 0;
            width: 16%;
            text-align: $ldirection;
          }
        }
        .product--teaser {
          .product__detail {
            margin-#{$ldirection}: 9%;
            width: 25%;
            h3 {
              letter-spacing: normal;
            }
          }
          .product__qty {
            margin-#{$ldirection}: 28%;
            text-align: center;
          }
          .product__rate {
            margin-#{$ldirection}: 47%;
          }
          .product__price {
            margin-#{$ldirection}: 65%;
          }
          .product__add-to-bag {
            width: 100%;
            font-size: 10px;
            line-height: 24px;
            height: 34px;
            @include swap_direction(padding, 5px 0);
            @if $fonts_update == true {
              letter-spacing: normal;
            }
          }
        }
      }
    }
  }
}

.order-details-page {
  .cart-item {
    &__price {
      .cart-item {
        &__product-price--sale {
          color: $color-text-red;
        }
      }
    }
  }
}

.address_form_container {
  .ship_type_container {
    margin-top: 15px;
  }
  .picker-radio {
    float: left;
    width: 40%;
  }
}

.address {
  .state_container {
    .select2-container {
      display: none;
    }
    select {
      display: block !important;
    }
  }
}

.gender_col {
  display: inline-block;
  width: 100%;
  .label-content {
    width: 20%;
    float: left;
    margin-top: 4%;
  }
  .picker-radio {
    width: 34%;
  }
}

.past-purchases-page__content {
  .past-purchases {
    .product__footer {
      .product-item__out-of-stock {
        bottom: 20px;
      }
      a.notify_me {
        margin: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
}

.site-container {
  .past-purchases-page {
    .past-purchases {
      .grid--mpp {
        .product__footer {
          .product__add-to-bag {
            display: flex !important;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.order-timeline {
  ul {
    display: flex;
    max-width: 340px;
    margin: auto;
  }
}

.order-timeline__step {
  text-align: center;
  position: relative;
  pointer-events: none;
  opacity: 0.3;
  flex: 1;
  + .order-timeline__step {
    margin-left: 25px;
    &:before {
      content: '';
      border-top: 1px solid $color--black;
      width: 100%;
      position: absolute;
      top: 10px;
      transform: translate(calc(-100% - 13px));
    }
  }
  + .order-timeline__step_large {
    &:before {
      width: calc(100% - 18px);
    }
    + .order-timeline__step {
      &:before {
        width: calc(100% + 20px);
      }
    }
  }
}

.order-timeline__step_active {
  opacity: 1;
  pointer-events: auto;
}

.order-timeline__step.order-timeline__step_large {
  .order-timeline__step-title {
    max-width: none;
  }
}

.order-timeline__step-bullet {
  background-color: $color--black;
  border-radius: 100%;
  font-size: 14px;
  color: $color--white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  width: 25px;
  height: 25px;
}

.order-timeline__step_cancel .order-timeline__step-bullet {
  background-color: $color--dark-red;
}

.order-timeline__step-title {
  max-width: 60px;
  margin: auto;
  @if $fonts_update == true {
    font-size: 10px;
  } @else {
    font-size: 14px;
  }
}

.order-timeline__step-subtitle {
  font-family: inherit;
  font-size: 10px;
}

.payment-info {
  &.panel {
    display: none;
  }
}

.sign-in-container .new-account {
  .password-criteria {
    text-transform: uppercase;
    font-size: 14px;
    color: $color--gray-warning;
    p {
      margin-bottom: 3px;
    }
    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}
