.signin-panel,
#return-user {
  .js-forgot-pw-note {
    margin-bottom: 15px;
  }
}

.ship-type {
  .picker-radio {
    float: left;
  }
  label {
    display: block;
  }
}

.shipping-edit-content {
  #continue-btn {
    clear: both;
  }
  #checkout_gift_option_container {
    display: block;
  }
  .gift-options__content {
    display: none;
  }
}

#checkout_billing {
  .accepted_privacy {
    height: 200px;
    overflow: auto;
    border: 1px solid $border-color;
    padding: 15px;
  }
}

.city-state-zip {
  .state-cointainer {
    .select2-container {
      display: none;
    }
    select {
      display: block !important;
      height: 58px;
    }
  }
}

.checkout {
  .checkout-progress-bar {
    margin: 20px 0 0;
    &__list {
      overflow: hidden;
      counter-reset: step;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    &__list-item {
      color: #f0f0f0;
      text-transform: uppercase;
      width: 33.33%;
      float: left;
      position: relative;
      text-align: center;
      font-size: 12px;
      @if $fonts_update == false {
        font-family: $ano-bold_regular-font;
      }
      a {
        border-bottom: none;
        color: #f0f0f0;
        text-decoration: none;
        &:hover,
        &:visited {
          color: #f0f0f0;
          text-decoration: none;
        }
      }
      &:before {
        background: $color--white;
        border: 1px solid #f0f0f0;
        color: $color--white;
        content: counter(step);
        counter-increment: step;
        display: block;
        line-height: 13px;
        margin: 0 auto 5px auto;
        position: relative;
        width: 13px;
        z-index: 2;
        @include border-radius(7.5px);
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: #f0f0f0;
        position: absolute;
        left: -50%;
        top: 7px;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &--complete {
        color: #000000;
        font-weight: bold;
        a {
          color: #000000;
          &:hover,
          &:visited {
            color: #000000;
          }
        }
        &:before {
          background: #000000;
          color: #000000;
        }
      }
    }
  }
  .checkout__sidebar {
    .product__desc {
      .add-to-cart {
        .btn {
          font-size: 12px;
          padding: 0 12px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
    .order-summary-content {
      display: none;
    }
  }
  .checkout__content {
    .email_promotions {
      .picker {
        @include swap_direction(margin, 15px 1em 15px 0);
      }
    }
    .registration-panel {
      padding-bottom: 0;
    }
    .payment-panel {
      padding-top: unset;
      .payment-display {
        .address .billing-address {
          margin-bottom: 1.5em;
        }
      }
      &.edit {
        margin-top: 1.5em;
      }
    }
  }
  .left {
    .guarantee-panel {
      display: none;
    }
    .shipping-panel {
      border-bottom: unset;
      padding: 1em 0 0.3em 0;
      .shipping-address-display {
        margin-bottom: 2em;
      }
    }
  }
  .order-summary-panel {
    margin-bottom: 2.1em;
    .field-container {
      .form-item {
        .ship-method {
          width: auto;
          label.order-summary-panel-label {
            display: block;
            float: left;
            margin-right: 7px;
          }
          a.edit {
            text-decoration: underline;
            border-bottom: 0;
            float: left;
          }
        }
      }
    }
  }
  #viewcart-panel {
    .cart-item__qty {
      font-size: 15px;
      padding: 0;
      text-align: $ldirection;
    }
    .cart-item__price {
      .cart-item {
        &__product-price--non-sale {
          text-decoration: line-through;
        }
        &__product-price--sale {
          color: $black;
        }
      }
    }
  }
}

.checkout__content {
  .payment-edit-content {
    .payment-type {
      .payment-container {
        .payment-form {
          .payment-form__pp_cash {
            .cash_type_field {
              display: none;
            }
          }
        }
      }
    }
  }
  .review-panel {
    #adyen-payment #adyen-payment-form {
      margin-top: 10px;
      .chckt-pm-card .chckt-pm__details {
        .chckt-form-label {
          &--exp-date {
            width: 50%;
          }
          &--cvc {
            width: 45%;
          }
        }
      }
      .chckt-button {
        &:not(.chckt-button--disabled) {
          background-color: $color--black;
        }
      }
    }
  }
}

.panel select {
  border-width: 1px !important;
}

.viewcart {
  .checkout__content {
    margin-bottom: 40px;
    .order-summary-panel {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      .order-summary__content {
        .label,
        .value {
          font-size: 18px;
          @include swap_direction(margin, 0 0 18px 0);
          line-height: 20px;
        }
      }
    }
    .cart-item {
      .cart-item__total {
        margin-top: 1.25em;
      }
    }
    .order-summary-sidebar {
      display: none;
    }
  }
}
.kit-container {
  @include swap_direction(margin, 0 0 0 17%);
  padding: 10px 0 0 0;
  clear: both;
  .checkout__sidebar & {
    margin: 0;
    padding: 0;
    clear: both;
  }
  .cart-item {
    &__desc-info {
      margin: 0 0 20px 0;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #{$color--black};
      &:last-child {
        margin: 0;
        padding: 0;
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      margin: 0;
    }
  }
}
.order-details-page {
  .kit {
    .cart-item__thumb {
      width: 50%;
      float: left;
      margin-right: -100%;
      clear: none;
      padding-right: 1em;
    }
    .cart-item__desc-container {
      width: 50%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
      clear: right;
      .product_name,
      .cart-item__total {
        font-family: $ano-black_regular-font;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 0;
        margin-top: 1em;
        margin-bottom: 0.5em;
        line-height: 1;
      }
      .cart-item__total {
        width: 50%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        clear: none;
        padding-right: 0;
        letter-spacing: 0;
        margin-top: 1.25em;
      }
    }
    .kit-container {
      margin: 0 0 0 10%;
      .product_name {
        font-family: $ano-black_regular-font;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 0;
        margin-top: 1em;
        margin-bottom: 0.5em;
        line-height: 1;
      }
    }
  }
}
.enhanced-cart-page {
  .checkout.viewcart {
    .checkout-panel--loyalty-points {
      padding-top: 50px;
    }
    .sticky-checkout-button {
      .continue-checkout__lock--icon {
        display: none;
      }
    }
    .checkout__sidebar {
      .need-help-panel__content {
        .link {
          margin: 23px 0;
        }
      }
      .links-panel__header {
        padding: 5px;
      }
      .order-summary-panel {
        .content {
          .order-summary__shipping-content {
            .field-container {
              .form-item {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
    .accordion-panel.js-loyalty-section {
      margin: 10px 0;
      .loyalty-offer-code-panel {
        position: relative;
        .loyalty-offer-code-panel__title.checkout__panel-title {
          i {
            display: none;
          }
        }
        .offer-code-wallet__code {
          &__description-title {
            font-family: $ano-black_regular-font;
          }
          &__description-buttons {
            position: relative;
            margin-top: 15px;
            .offer-code-wallet__button {
              &--apply {
                position: absolute;
                background-color: $color--white;
                border: 1px solid;
                color: $black;
                margin: 0;
                padding: 10px;
                width: 100%;
                &.disabled {
                  background-color: $color--disabled;
                  color: $color--black;
                }
              }
              &--remove {
                position: absolute;
                width: 100%;
                padding: 15px;
                height: 45px;
                background-color: $black;
                color: $color--white;
                margin: 0;
              }
            }
          }
        }
        .offer-code-content__wallet {
          .slick-slide {
            padding-top: 20px;
          }
        }
      }
    }
    .checkout__content {
      .recommended-products-panel__title {
        @include swap_direction(margin, 22px 20px 19px 23px);
        width: 90%;
      }
    }
    .cart-item__add-to-favorites {
      font-size: 12px;
    }
  }
}
a.bto_gerar_boleto {
  color: $color--white;
  &:visited {
    color: $color--link-grey;
  }
}
