.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after {
  position: relative;
  bottom: 21px;
  font-family: 'icons';
  font-size: 24px;
  float: right;
  display: inline-block;
  content: '';
}

.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.csstransforms .lp_desktop #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_1 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_2 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_3 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_4 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_5 .lp_lpview_content:after,
.csstransforms .lp_mobile #lpChat .lp_pages_area #LP_DropDownQuestion_6 .lp_lpview_content:after {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  margin-top: -4.5px;
  border-style: solid;
  border-color: transparent;
  border-left-width: 9px;
  border-right-width: 9px;
  border-top-width: 0;
  border-bottom-width: 9px;
  border-bottom-color: black;
  margin-top: 6px;
}

@mixin txttrans() {
  &::-webkit-input-placeholder {
    text-transform: capitalize;
  }
  &:-moz-placeholder {
    text-transform: capitalize;
  }
  &:-ms-input-placeholder {
    text-transform: capitalize;
  }
  &::-moz-placeholder {
    text-transform: capitalize;
  }
}

#lpChat {
  input[type='text'] {
    @include txttrans();
  }
  input[type='email'] {
    @include txttrans();
  }
  input[type='number'] {
    @include txttrans();
  }
  textarea {
    @include txttrans();
  }
  select {
    text-transform: capitalize;
    text-decoration: none;
  }
  option {
    text-transform: capitalize;
  }
  .lpview_form_textarea::-webkit-input-placeholder {
    text-transform: uppercase;
  }
  .lpview_form_textarea:-moz-placeholder {
    text-transform: uppercase;
  }
  .lpview_form_textarea:-ms-input-placeholder {
    text-transform: uppercase;
  }
  .lpview_form_textarea::-moz-placeholder {
    text-transform: uppercase;
  }
}

.lp_desktop {
  #lpChat {
    .lp_survey_area {
      .lp_pages_area {
        .lp_question_label {
          color: #000;
          text-transform: capitalize;
        }
      }
      .lp_header_text {
        color: #000;
      }
    }
  }
}

@if $password_strengthen {
  input[type='submit'].profile-password-update__button {
    height: 60px;
    border: 1px solid $color--very--light--grey;
  }
}
