.content {
  .submenu--sticky {
    .submenu__content {
      .submenu__nav {
        li:last-child {
          display: none;
        }
      }
    }
  }
  .submenu {
    &.submenu--sticky {
      &.appointment-nav {
        z-index: 99;
      }
    }
  }
  .field-content {
    .sd-appt-booking {
      .elc-appt-booking_stepper {
        margin-top: 21%;
      }
    }
  }
}

.sd-appt-booking {
  .elc-appt-booking_mobile-store-selector {
    .elc-button {
      width: fit-content;
    }
  }
}

.appt-book {
  .my-appointments {
    .appointment-container {
      .appointment-details {
        & > div.date-time {
          text-transform: none;
        }
      }
    }
  }
}

.field-content {
  .sd-appt-booking {
    .elc-appt-booking_store-selection-container {
      .elc-appt-booking_mobile-store-selector {
        div {
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }
}
