// @file _cms.scss
// \brief: These styles used to be embedded in CMS nodes

ul.heroes-texto {
  margin-left: 10px;
  li {
    text-align: left;
    &:before {
      content: '\2022';
      color: $color--black;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

ul.heroes-texto-white {
  li {
    text-align: left;
    &:before {
      content: '\2022';
      color: $color--white;
      display: inline-block;
      width: 1em;
      margin-left: 0em;
    }
  }
}

.heroes-texto-white {
  font-family: $ano-bold_regular-font;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.25;
  margin-bottom: 0.1875em;
  margin-top: 0.1875em;
  color: $color--white;
  font-size: 1.8em;
}

.heroes-texto {
  font-family: $ano-bold_regular-font;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.25;
  margin-bottom: 0.1875em;
  margin-top: 0.1875em;
  color: $color--black;
  font-size: 1.8em;
}

.heroes-title {
  color: $color-magenta-shadow;
  text-align: left;
}

.heroes-subtitle {
  color: $color-magenta-shadow;
}

.multi-use-tout--align-left {
  .multi-use-tout__caption-inner-inner {
    margin-top: 30%;
  }
}

.grid--mpp__item {
  .slick-slide {
    .slick-active {
      height: auto;
    }
  }
}

.p-texto {
  color: $color--white;
  font-size: 1.5em;
}

.button-black {
  background-color: $color--black;
  width: 131px;
  margin-left: 28%;
  position: relative;
  top: 191px;
}

.text-button {
  color: $color--white;
  padding: 7%;
}

.menor {
  font-size: 1.8em;
}

.men-texto {
  font-family: $ano-bold_regular-font;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.25;
  margin-bottom: 0.1875em;
  margin-top: 0.1875em;
  color: $color--white;
  font-size: 1.8em;
}

.men-title {
  color: $color--white;
}

.basic-carousel-item {
  .basic-carousel-item-copy__header-wrapper.center-bottom {
    bottom: 0;
  }
}

.quicklinks-tout {
  .quicklinks-tout__content {
    height: 339px;
    background-color: $color--black;
  }
}

.quicklinks-tout__image img {
  width: 278px;
}

.js-single-column {
  .mobile-collection-slide {
    .product__product-details-shade {
      display: block;
      margin-bottom: 10px;
      margin-top: 15px;
    }
  }
}

.foundation-finder__left {
  h1 {
    font-size: 41px;
  }
}

.foundation-finder__chat {
  .foundation-finder__chat--button {
    &:first-child {
      display: none;
    }
    &:nth-child(n + 1) {
      margin: 0 20px 15px -4px;
    }
  }
}

.js-single-column {
  .product--teaser {
    margin-top: 46px;
  }
}

.product__detail {
  .product__name-link {
    margin-top: 50px;
  }
}

.btn-agendamento {
  border-bottom: none;
  color: $color--white;
  font-family: $ano-bold_regular-font;
  font-size: 16px;
  letter-spacing: -0.69px;
  line-height: get-line-height(24px, 24px);
  border: 1px solid $color--white;
  border-radius: 1px;
  padding: 15px 25px;
  cursor: pointer;
  transition: 0.8s;
  color: $color--white;
  &:hover {
    background-color: $color--white;
    color: $color-magenta-red;
  }
}

.title_virtual_lp {
  font-size: 52px;
  color: $color--yellow-shade;
  font-family: $ano-bold_regular-font;
  line-height: get-line-height(50px, 50px);
  font-weight: lighter;
}
@media (min-width: 376px) {
  .mobile-top {
    margin-bottom: 92px;
  }
}
@media (max-width: 375px) {
  .mobile-top {
    margin-bottom: 40px;
  }
}

.back-stock {
  width: 131px;
  padding: 0px;
  height: 0;
  border-top: 0;
  border-bottom: 31px solid $color--black;
  border-left: 31px solid $color--black;
  border-right: 30px solid $color--black;
  transform: rotate(-46deg);
  position: absolute;
  top: 21px;
  margin: 0;
  left: -36px;
  line-height: get-line-height(28px, 28px);
  font-size: 8px;
  text-align: center;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  white-space: nowrap;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $color-orange;
  }
}

#face-body-banner {
  max-width: 1281px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
#face-body-bannen {
  width: 100%;
  height: 537px;
  margin: 0 auto;
}

#lpd {
  width: 1281px;
  height: 304px;
  margin: 0 auto;
  margin-top: -57px;
}
#lnd {
  width: 100%;
  height: 537px;
  margin: 0 auto;
}

#sale-geral-hp {
  margin: 0 auto;
  margin-top: -57px;
  width: 100%;
  height: 537px;
}
#step-section {
  margin-top: -57px;
  width: 100%;
  height: 537px;
  margin: 0 auto;
}

#nossas-lojas-abertas {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.tips {
  max-width: 26%;
  height: auto;
  margin-bottom: 60px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  img {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
@media (max-width: 745px) {
  #nossas-lojas {
    display: block;
  }
  .tips {
    max-width: 100%;
  }
}

#menu-sale {
  max-width: 1270px;
  min-width: 67%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: -37px;
  width: 97%;
  margin: -4px auto 0 auto;
  .item-sale {
    width: 10%;
    height: auto;
    margin-bottom: 12px;
    border: none;
    height: auto;
    margin-bottom: 5px;
    &:hover {
      border-bottom: 4px solid $color--tos-red;
    }
  }
}

.info-guia {
  font-size: 16px;
  width: 53%;
  margin: 0 auto;
  font-weight: lighter;
  padding-bottom: 83px;
  box-sizing: border-box;
}
@media (max-width: 748px) {
  .mob-img-icone {
    width: 7%;
  }
  .info-guia {
    width: 95%;
  }
}

#header-reabertura {
  h4 {
    margin-top: 40px;
  }
}

#content-presents {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
#presentes-options {
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto 50px auto;
  justify-content: space-between;
}
.option {
  max-width: 675px;
  max-height: 560px;
}
@media (max-width: 600px) {
  #content-presents {
    max-width: 80%;
  }
  #presentes-options {
    flex-direction: column;
    margin: 0 auto;
  }
  .option {
    padding-bottom: 52px;
  }
}

#viva-glam-section {
  width: 100%;
  height: 537px;
  margin: 0 auto;
  max-width: 1281px;
  max-height: 304px;
}
#video-viva-glam {
  width: 72%;
  margin: 50px auto;
  width: 400px;
}
@media only screen and (max-width: 768px) {
  #video-viva-glam {
    width: 100%;
  }
}
//check
#banner-oferta-atual {
  max-width: 1600px;
  min-width: 80%;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: auto;
  margin: 0 auto 20px auto;
}
#banner-oferta-atual-1 {
  max-width: 1280px;
  min-width: 70%;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

#banner-oferta-atual-principal {
  max-width: 1600px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

#banner-oferta {
  width: 1440px;
  margin: 0 auto;
}

#barbie-back-in-stock {
  width: 1281px;
  height: 304px;
  margin: 0 auto;
}

.form-item {
  margin-bottom: 15px;
  justify-content: center;
  margin-bottom: 15px;
}
.form-item.submit-button {
  margin: 0 0 20px;
  position: absolute;
  padding-top: 1px;
  top: 4%;
  right: 12%;
  z-index: 99999;
}

#waitlist-signup {
  .waitlist-signup-block {
    padding: 0 30px 30px;
  }
}

.waitlist-signup-container {
  .email_signup_copy {
    color: $color--white;
  }
}

.bopis-edit-bag {
  .button {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
}
.btn {
  height: 65.5px;
  line-height: get-line-height(15px, 65px);
}
.custom-grid__item {
  .login_signup__submit {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
}
.events-near-you {
  .media-block--event {
    .media-block__cta {
      height: 65.5px;
      line-height: get-line-height(65px, 65px);
    }
  }
}
.finder-step__ctas {
  a {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
}
.gift-card-physical__buttons {
  > input {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
}
.invis-search__container {
  .invis-form__btn--submit {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
  .invis-sku__add-to-cart {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
}
.product {
  .product__inventory-status {
    li.coming-soon-text {
      height: 65.5px;
      line-height: get-line-height(65px, 65px);
    }
  }
}
.product--teaser.product--shaded {
  .product__link-to-spp {
    height: 65.5px;
    line-height: get-line-height(65px, 65px);
  }
}
.product-full__smart-traffik {
  height: 65.5px;
  line-height: get-line-height(65px, 65px);
}
.product__add-to-bag {
  height: 65.5px;
  line-height: get-line-height(65px, 65px);
}
.product__link-custom {
  height: 65.5px;
  line-height: get-line-height(65px, 65px);
}
.product__replenish {
  height: 65.5px;
  line-height: get-line-height(65px, 65px);
}
.site-header {
  .site-header__tools {
    .site-bag__contents {
      .cart-product-button {
        height: 65.5px;
        line-height: get-line-height(65px, 65px);
      }
    }
    .site-bag__contents-checkout {
      height: 65.5px;
      line-height: get-line-height(65px, 65px);
    }
    .site-email-signup__contents-submit {
      height: 65.5px;
      line-height: get-line-height(65px, 65px);
    }
    .site-my-mac__contents-submit {
      height: 65.5px;
      line-height: get-line-height(65px, 65px);
    }
  }
}
input[type='submit'] {
  height: 65.5px;
  line-height: get-line-height(65px, 65px);
}
.form--newsletter__subtitle {
  text-align: center;
}

#speech-iza {
  height: auto;
  width: 1280px;
  margin: 100px auto 0;
  position: relative;
}

.txt-speech-iza {
  width: 600px;
  position: absolute;
  top: 18%;
  right: 52%;
  color: $color--white;
  font-size: 5rem;
  text-transform: uppercase;
}

.txt-speech-iza h2 {
  font-size: 4rem;
  text-transform: none;
}

.txt-speech-iza a {
  text-transform: uppercase;
  text-decoration: none;
  background: $color--white;
  border: none;
  padding: 14px 50px;
  font-size: 2rem;
  font-family: $ano-bold_regular-font;
}

#speech-iza .img-cor-batom {
  float: right;
  width: 62%;
}

#speech-iza .img-cor-batom img {
  margin-left: 156px;
}

#speech-iza {
  height: auto;
  width: 100%;
  margin: 50px 0 0;
}

.txt-speech-iza {
  width: 90%;
  margin: 0 auto 30px auto;
  color: $color--white;
  font-size: 5rem;
  text-transform: uppercase;
  padding-left: 20px;
}

.txt-speech-iza h2 {
  font-size: 32px;
  line-height: get-line-height(32px, 32px);
  text-transform: none;
}

.txt-speech-iza a {
  text-transform: uppercase;
  text-decoration: none;
  background: $color--white;
  border: none;
  padding: 14px 50px;
  font-size: 2rem;
  font-family: $ano-bold_regular-font;
}

#speech-iza .img-cor-batom {
  width: 100%;
}
#fotos-iza {
  margin: 100px auto;
  width: 100%;
}
.txt-batom-iza {
  color: $color--white;
  width: 90%;
  margin: 0px auto;
  padding-left: 20px;
  h2 {
    font-size: 32px;
    text-transform: none;
    line-height: get-line-height(32px, 32px);
  }
}
#imgs-iza-batom {
  justify-content: space-between;
  display: none;
  width: 100%;
}
#txt-header {
  width: 652px;
  margin: 50px auto 10px auto;
  text-align: center;
}
@media (max-width: 745px) {
  #txt-header {
    width: 95%;
  }
}

.img-item {
  width: 80%;
  margin: 0 auto;
}

#header-iza {
  height: auto;
  margin: 0 auto 100px;
  width: 100%;
}
#top-vitrine-queridinhos {
  color: $color--white;
  text-align: center;
  width: 90%;
  margin: 50px auto 0;
  h2 {
    font-size: 5rem;
  }
  p {
    font-size: 2rem;
  }
}

.waitlist-signup-container {
  width: 57%;
  margin: 0 auto;
  .email_signup_tickbox {
    float: left;
  }
  .submit-button {
    text-align: center;
  }
  a {
    color: $color--white;
    text-transform: unset;
  }
  .email_signup_copy {
    padding-top: 5px;
    color: $color--white;
    text-transform: initial;
    font-weight: normal;
    font-family: $roboto-mono_regular-font;
    font-size: 1.2rem;
  }
}

#footer-baile {
  color: $color--white;
  text-align: center;
  h4 {
    margin-bottom: 0.8em;
    font-weight: lighter;
    letter-spacing: 1.2rem;
    font-size: 3rem;
    margin-top: 56px;
  }
  h5 {
    color: $color--yellow-shade;
  }
}
#endereco {
  background-image: url('/media/export/cms/offers/CJGK-759/ESTEELAUDE-1434-faixa.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $color--black;
  text-transform: uppercase;
  width: 100%;
  height: 184px;
  margin: -22px auto 0;
  padding: 59px;

  h5 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0;
    margin-top: 1px;
    line-height: 1;
    letter-spacing: 1px;
    font-size: 17px;
  }
}
#trajes {
  color: $color--white;
  width: 80%;
  margin: 0 auto;
  h4 {
    margin-top: 23px;
  }
  p {
    font-size: 19px;
    letter-spacing: 1px;
  }
  .traje-info {
    color: $color--yellow-shade;
  }
}

.container-txt {
  color: $color--white;
  clear: both;
  padding-top: 84px;
  h2 {
    font-family: $ano-bold_regular-font;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1;
    width: 62%;
    margin: 0 auto;
    padding-top: 20px;
    font-size: 51px;
    text-align: center;
  }
  p {
    font-family: $roboto-mono_regular-font;
    letter-spacing: 1px;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    font-weight: 100;
  }
}

.container {
  width: 80%;
  margin: 0 auto;
}
// Only required while the above is still set, once above removed, this is not required.
.product-foundation-vto {
  .container {
    width: 100%;
  }
}
.wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-template-rows: repeat(3, minmax(262px, auto));
  grid-auto-rows: minmax(262px, auto);
  p {
    color: $color--white;
    text-align: right;
  }
  div {
    align-self: center;
    background-color: $color--black;
    &:nth-child(2) {
      place-items: end end;
    }
    &:nth-child(even) {
      background-color: $color--black;
    }
    &:nth-child(1) {
      grid-column-start: 1;
    }
    &:nth-child(14) {
      align-self: baseline;
    }
  }
}

#banner-oferta-atual {
  max-width: 1269px;
  height: auto;
  width: 97%;
  margin: 20px auto 20px auto;
}

.tout-block-portrait {
  .bg-img {
    opacity: 0.5;
  }

  .tout-block-portrait__caption {
    transform: translateY(14%);
  }
}

#txt-tout-2 {
  color: $color--white;
}

#shade-finder {
  width: 100%;
  margin: 0 auto;
}

.tout-block-include {
  .tout-block-include__text-container--pad-left {
    padding: 0px 36px 0 36px !important;
  }
}

@keyframes mudarCor {
  0% {
    color: $color--white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color--black;
  }
  50% {
    color: $color--black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color--black;
  }
  100% {
    color: $color--white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color--black;
  }
}

.pisca {
  animation: mudarCor 1s step-start 2s infinite alternate;
  font-size: 30px;
  line-height: get-line-height(35px, 35px);
  font-weight: bolder;
  font-family: $ano-bold_regular-font;
  margin-top: 10px;
  margin-bottom: 0px;
}

@keyframes pisca {
  0% {
    text-shadow: none;
    font-weight: 100;
    -webkit-text-stroke: 0px;
  }
  50% {
    text-shadow: 2px 2px 2px $color--red, 0px 2px 22px $color--light--orange;
    font-weight: 600;
    -webkit-text-stroke: 1px $color--darker-gray;
  }
  100% {
    text-shadow: none;
    font-weight: 100;
    -webkit-text-stroke: 0px;
  }
}
@keyframes pisca-delay {
  0% {
    text-shadow: none;
    font-weight: 100;
    -webkit-text-stroke: 0px;
  }
  50% {
    text-shadow: 2px 2px 2px $color--red, 0px 2px 22px $color--light--orange;
    font-weight: 600;
    -webkit-text-stroke: 1px $color--darker-gray;
  }
  100% {
    text-shadow: none;
    font-weight: 100;
    -webkit-text-stroke: 0px;
  }
}
.pisca-pisca {
  animation: pisca 3s infinite;
  text-shadow: 2px 2px 2px $color--red, 0px 2px 22px $color--light--orange;
  font-weight: 600;
  -webkit-text-stroke: 1px $color--darker-gray;
}
.pisca-pisca-delay {
  animation: pisca-delay 3s infinite;
  text-shadow: 2px 2px 2px $color--red, 0px 2px 22px $color--light--orange;
  font-weight: 600;
  -webkit-text-stroke: 1px $color--darker-gray;
}

.art_p {
  color: $color--white;
  font-family: $helvetica-font;
  line-height: get-line-height(24px, 24px);
  margin-bottom: 0;
  text-transform: lowercase;
  font-size: 16px;
}

#txt-offer {
  color: $color--white;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  a {
    color: $color--white;
    text-transform: lowercase;
    text-decoration: none;
    border: none;
  }
}

#logo-tranchesis {
  padding-top: 18px;
  img {
    max-width: 70%;
    margin-bottom: -50px;
  }
}

.hero-block__link {
  a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.baloon {
  position: absolute;
  font-size: 11px;
  top: 12px;
  left: 86%;
  background: $color-magenta-red;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 12px;
  width: 178px;
  text-align: justify;
  box-shadow: 3px 3px 3px $color--black;
  color: $color--white;
  top: 20px;
  left: 5%;
  p {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  strong {
    font-size: 15px;
    font-family: $ano-bold_regular-font;
  }
  .arrow {
    width: 0;
    height: 0;
    display: block;
    border-width: 10px;
    border-color: $color-magenta-red;
    border-style: solid;
    position: absolute;
    top: 20px;
    left: 10px;
  }
}
@media (max-width: 1280px) {
  .baloon {
    top: -438px;
    left: 0%;
  }
}

#cashback-offers {
  max-width: 97%;
  margin: 14px auto;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background: linear-gradient(
    90deg,
    rgba(218, 175, 221, 1) 0%,
    rgba(225, 199, 238, 1) 36%,
    rgba(181, 195, 239, 1) 61%,
    rgba(145, 178, 225, 1) 100%
  );
}
.cashback-content {
  width: 70%;
  margin: 0 auto;
  width: 90%;
  h3 {
    color: $lux-submit-button;
    font-size: 21px;
  }
  p {
    font-family: $helvetica-font;
    font-size: 17px;
    margin-bottom: 4px;
    color: $color--white;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
}
.cashback-obs {
  p {
    font-size: 8px;
    margin-bottom: 2px;
  }
}

.box-services {
  color: $color--white;
  padding: 45px 5px 0px 5px;
  width: auto;
  h3 {
    font-size: 2.3em;
  }
}
.services-txt {
  display: flex;
  justify-content: space-around;
}
.topic-services {
  padding: 10px 20px;
  width: 44%;
  text-align: left;
  font-size: 13px;
}

.padding-left-5 {
  padding-left: 3%;
}
.padding-left-10 {
  padding-left: 8%;
}
.padding-bottom-5 {
  padding-bottom: 2%;
}
.padding-top-0 {
  padding-top: 17%;
}
.title--h3 {
  line-height: 1;
  text-align: left;
}

@media (max-width: 1022px) {
  div.mobile-padding-left-5 {
    padding-left: 4%;
  }
  div.mobile-padding-top-5 {
    padding-top: 73%;
  }
  #node-337632 {
    .align-items-center {
      align-items: unset;
    }
  }
  .horizontal-align-center {
    align-items: unset;
  }
}

#entrega-super-expressa {
  display: flex;
  align-items: center;
}
.content-entrega {
  background-color: $color--white;
  width: 60%;
  margin: 25px auto;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-family: $ano-bold_regular-font;
    font-size: 22px;
    color: $color--black;
    margin-bottom: 0;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    font-family: $helvetica-font;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
@media (max-width: 900px) {
  .content-entrega {
    background-color: $color--white;
    width: 95%;
    margin: 20px auto;
    padding: 20px;
    display: block;
    text-align: center;
    h3 {
      font-size: 16px;
      font-family: $ano-bold_regular-font;
    }
    p {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
  #node-328248 {
    a {
      width: 149px;
      margin: 0 auto;
    }
    .js-elc-button {
      .button {
        &:hover {
          color: $color--white;
        }
      }
    }
  }
}

#reabertura_lojas {
  width: 100%;
  margin: 0 auto;
  background-image: url('/media/export/cms/offers/19722103/Banner_Reabertura _bg.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: auto;
  background-color: $color--chetwode-light;
  .content-reabertura {
    width: 56.5%;
    margin-left: 43.5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    border: none;
    text-decoration: none;
    height: 100%;
    padding: 14px 10px 20px 10px;
    h2 {
      font-size: 3rem;
    }
    p {
      font-family: $helvetica-font;
      font-size: 14px;
    }
    a {
      font-family: $ano-bold_regular-font;
      border-bottom: 1px solid $color--black;
      font-size: 19px;
      width: 100px;
      margin: 0 auto;
    }
  }
}

.tout-block-landscape__cta {
  background-color: $color--black;
}
